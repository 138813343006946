import React from 'react';
import './DynamicGradient.css'; // Make sure this is correctly pointing to your CSS file

const DynamicGradients = () => {
  return (
    <div className="container">
      <div className="red-gradient"></div>
      <div className="blue-gradient"></div>
      <div className="ball"></div> {/* Existing ball */}
      <div className="ball2"></div> {/* New element */}
    </div>
  );
};

export default DynamicGradients;
