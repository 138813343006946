import React, { useState } from 'react';
import { useGlitchEffect } from './glitchHover';
import './Header.css';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const [aboutText, aboutOnMouseEnter, aboutOnMouseLeave] = useGlitchEffect("START");
  const [whyText, whyOnMouseEnter, whyOnMouseLeave] = useGlitchEffect("FEATURES");
  const [faqText, faqOnMouseEnter, faqOnMouseLeave] = useGlitchEffect("FAQ");
  const [teamText, teamOnMouseEnter, teamOnMouseLeave] = useGlitchEffect("ROADMAP");
  const [dappText, dappOnMouseEnter, dappOnMouseLeave] = useGlitchEffect("CONTACT");
  const [docsText, docsOnMouseEnter, docsOnMouseLeave] = useGlitchEffect("DOCS");

  return (
    <header className="header">
      <div className="logo">PLAY Prophecy</div>
      <nav className={`nav ${isNavOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <a href="#What" onMouseEnter={aboutOnMouseEnter} onMouseLeave={aboutOnMouseLeave}>
              {aboutText}
            </a>
          </li>
          <li>
            <a href="#Why" onMouseEnter={whyOnMouseEnter} onMouseLeave={whyOnMouseLeave}>
              {whyText}
            </a>
          </li>
          <li>
            <a href="#Roadmap" onMouseEnter={faqOnMouseEnter} onMouseLeave={faqOnMouseLeave}>
              {faqText}
            </a>
          </li>
          <li>
            <a href="#Team" onMouseEnter={teamOnMouseEnter} onMouseLeave={teamOnMouseLeave}>
              {teamText}
            </a>
          </li>
          <li>
            <a href="#dapp" onMouseEnter={dappOnMouseEnter} onMouseLeave={dappOnMouseLeave}>
              {dappText}
            </a>
          </li>
          <li>
            <a href="#docs" onMouseEnter={docsOnMouseEnter} onMouseLeave={docsOnMouseLeave}>
              {docsText}
            </a>
          </li>
        </ul>
      </nav>
      <div className="header-group">
        <div className="social-icons">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
          <a href="https://discord.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
          <a href="https://telegram.org" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
        </div>
<div className="sandwich-menu" onClick={() => setIsNavOpen(!isNavOpen)}>
  <svg viewBox="0 0 100 80" width="40" height="40" fill="white">
    <rect width="100" height="5"></rect>
    <rect y="30" width="100" height="5"></rect>
    <rect y="60" width="100" height="5"></rect>
  </svg>
</div>

      </div>
    </header>
  );
};

export default Header;
