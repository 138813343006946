import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { useGlitchEffect } from './glitchHover'; 
import { useDynamicGlitchEffect } from './headlines'; 

const Section1 = () => {
  const parallaxImageRef = useRef(null);
  const [scale, setScale] = useState(1); // State to track scale for pulsing
  const [translateY, setTranslateY] = useState(0); // State to track translation for parallax
  const [currentPartnerIndex, setCurrentPartnerIndex] = useState(0); // State for carousel
  const [webAppText, webAppOnMouseEnter, webAppOnMouseLeave] = useGlitchEffect("WebApp");
  const [docsText, docsOnMouseEnter, docsOnMouseLeave] = useGlitchEffect("Docs");
  const headlineText = useDynamicGlitchEffect("Predictive Prophecy", 700); // Using dynamic glitch effect

  // Array of partner image filenames, assuming they're in the public directory
  const partners = [
    '/partner1.png', '/partner2.png', '/partner3.png',
    '/partner4.png', '/partner5.png', '/partner6.png', '/partner7.png'
  ];

  // Function for continuous pulsing
  useEffect(() => {
    const pulse = () => {
      const time = performance.now();
      const newScale = 1 + Math.sin(time / 1000) * 0.05; // Subtle pulsing effect
      setScale(newScale); // Update the scale state
    };
    const pulseInterval = setInterval(pulse, 100);
    return () => clearInterval(pulseInterval);
  }, []);

  // Function to handle parallax effect on scroll
  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.section');
      const scrolled = window.scrollY - section.offsetTop;
      const initialTop = 30; // Adjust to your initial top value

      // Parallax effect
      if (scrolled >= 0 && scrolled <= section.offsetHeight) {
        const newTranslateY = initialTop - scrolled * 0.1;
        setTranslateY(newTranslateY); // Update the translateY state
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Carousel effect
  useEffect(() => {
    const cyclePartners = () => {
      setCurrentPartnerIndex(prevIndex => (prevIndex + 1) % partners.length);
    };
    const partnersInterval = setInterval(cyclePartners, 3000);

    return () => clearInterval(partnersInterval);
  }, [partners.length]);

  // Apply the combined transform whenever scale or translateY changes
  useEffect(() => {
    if (parallaxImageRef.current) {
      parallaxImageRef.current.style.transform = `translate(-50%, -${translateY}%) scale(${scale})`;
    }
  }, [scale, translateY]);

  // Calculate a set of displayed partners based on the current index
  const displayedPartners = [];
  for (let i = 0; i < 5; i++) {
    displayedPartners.push(partners[(currentPartnerIndex + i) % partners.length]);
  }

  return (
    <section className="section">
      <div className="content-box">
      <h1 className="glitch-headline">{headlineText}</h1>
      <p><strong>Welcome to the frontier of prediction markets,</strong> where Prophecy redefines the landscape through gamification and decentralization. Our platform is a beacon for those who dare to foresee, offering a permissionless and interoperable ecosystem that thrives on smart contract technology.</p>
      <p><strong>Embark on a voyage of discovery and competition,</strong> navigating through an immersive world of prediction where your insights forge the path ahead. With Prophecy, predict, participate, and prosper in a community united by vision and validated by blockchain's transparency.</p>
        <div className="buttons-container">
        <a href="https://www.prophecy.markets/" target="_blank" rel="noopener noreferrer" className="styled-button-link">
  <button 
    className="styled-button" 
    onMouseEnter={webAppOnMouseEnter} 
    onMouseLeave={webAppOnMouseLeave}>
    {webAppText}
  </button>
</a>

      {/* Button for Docs */}
      <a href="https://docs.prophecy.fi/" target="_blank" rel="noopener noreferrer" className="styled-button-link">
  <button 
    className="styled-button" 
    onMouseEnter={docsOnMouseEnter} 
    onMouseLeave={docsOnMouseLeave}>
    {docsText}
  </button>
</a>
        </div>
        <img ref={parallaxImageRef} src="/section1.png" alt="Section 1" className="parallax-image"/>
      </div>

      {/* Partners Carousel */}
      <div className="partners-carousel">
        <h1> Integrated Technologies</h1>
        <div className="partners-window">
          {displayedPartners.map((partner, index) => (
            <img key={index} src={partner} alt={`Partner ${index + 1}`} className="partner-image"/>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section1;
