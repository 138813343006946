import React from 'react';
import './App.css'; // Make sure this points to your CSS file
import { useDynamicGlitchEffect } from './headlines'; // Adjust the path as necessary

const Section4 = () => {
  // Dynamic glitch effect for the headline
  const contactHeadline = useDynamicGlitchEffect("Get In Touch", 700, 5000);

  return (
    <section className="section contact-section">
      {/* Dynamic glitch effect headline */}
      <h1 className="dynamic-glitch-headline">{contactHeadline}</h1>
      
      {/* Contact container box */}
      <div className="contact-container">
        <p>Have questions, suggestions, or want to get involved? We're all ears! Reach out to us, and let's make the future of prediction markets together.</p>
        
        {/* Button with placeholder action */}
        <button className="styled-button">Send a Message</button>
      </div>
    </section>
  );
};

export default Section4;