// FAQItem.js
import React from 'react';
import { useGlitchEffect } from './glitchHover';

const FAQItem = ({ index, question, answer, isOpen, toggleFAQ }) => {
  const [glitchText, onGlitchMouseEnter, onGlitchMouseLeave] = useGlitchEffect(question);

  return (
    <div className="faq-item">
      <button
        className="faq-question"
        onClick={() => toggleFAQ(index)}
        onMouseEnter={onGlitchMouseEnter}
        onMouseLeave={onGlitchMouseLeave}
      >
        {isOpen ? glitchText : question}
      </button>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        {answer}
      </div>
    </div>
  );
};

export default FAQItem;
