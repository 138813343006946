import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { useGlitchEffect } from './glitchHover'; // Adjust the path as necessary
import { useDynamicGlitchEffect } from './headlines'; // Adjust the path as necessary

const Section2 = () => {
  const parallaxImageRef = useRef(null);
  const [scale, setScale] = useState(1); // State to track scale for pulsing
  const [translateX, setTranslateX] = useState(0); // State to track horizontal translation for parallax
  const [discoverText, discoverOnMouseEnter, discoverOnMouseLeave] = useGlitchEffect("Learn More");
  const [participateText, participateOnMouseEnter, participateOnMouseLeave] = useGlitchEffect("Join Now");
  const [thriveText, thriveOnMouseEnter, thriveOnMouseLeave] = useGlitchEffect("Get Started");
  const dynamicGlitchText = useDynamicGlitchEffect("The Future of Predictive Gaming", 700, 5000); // Adjust duration and repeat interval as needed

  useEffect(() => {
    const pulse = () => {
      const time = performance.now();
      const newScale = 1 + Math.sin(time / 1000) * 0.05; // Subtle pulsing effect
      setScale(newScale); // Update the scale state
    };
    const pulseInterval = setInterval(pulse, 100);
    return () => clearInterval(pulseInterval);
  }, []);

  // Function to handle parallax effect on scroll
  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.section');
      const scrolled = window.scrollY - section.offsetTop;
      const initialLeft = 30; // Adjust to your initial left value (center of the screen)

      // Parallax effect: Vertical scroll controlling horizontal movement
      if (scrolled >= 0 && scrolled <= section.offsetHeight) {
        const newTranslateX = initialLeft + scrolled * 0.2; // Adjust the rate as needed
        setTranslateX(newTranslateX); // Update the translateX state
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Apply the combined transform whenever scale or translateX changes
  useEffect(() => {
    if (parallaxImageRef.current) {
      parallaxImageRef.current.style.transform = `translate(${translateX}%, -50%) scale(${scale})`;
    }
  }, [scale, translateX]);

  return (
    <section className="section">
      <img ref={parallaxImageRef} src="/section2.png" alt="Section 2" className="parallax-image2"/>
      <h1 className="dynamic-glitch-headline">{dynamicGlitchText}</h1>
  
      <div className="content-box-container">
        {/* Box 1 */}
        <div className="content-box-about">
          <h2>Discover</h2>
          <p>Step into a gamified universe where each prediction opens doors to new dimensions. Explore, engage, and elevate your foresight across an ever-expanding panorama of possibilities.</p>
          <button 
            className="styled-button" 
            onMouseEnter={discoverOnMouseEnter} 
            onMouseLeave={discoverOnMouseLeave}>
            {discoverText}
          </button>
        </div>
  
        {/* Box 2 */}
        <div className="content-box-about">
          <h2>Participate</h2>
          <p>Compete or collaborate in our vibrant community. Propel your predictions to new heights in a dynamic, strategy-filled arena. No bets, just pure insight.</p>
          <button 
            className="styled-button" 
            onMouseEnter={participateOnMouseEnter} 
            onMouseLeave={participateOnMouseLeave}>
            {participateText}
          </button>
        </div>
  
        {/* Box 3 */}
        <div className="content-box-about">
          <h2>Thrive</h2>
          <p>Ascend the ranks, unlock new realms of rewards, and mark your legacy in the annals of Prophecy. Each prediction is your signature on the future.</p>
          <button 
            className="styled-button" 
            onMouseEnter={thriveOnMouseEnter} 
            onMouseLeave={thriveOnMouseLeave}>
            {thriveText}
          </button>
        </div>
      </div>
      {/* Long placeholder text below the boxes */}
      <p className="long-placeholder-text">
      Dive into Prophecy: the nexus where gamification meets prediction, crafting a world brimming with opportunities for insight and innovation. Here, predictions transcend mere bets, morphing into a riveting quest for foresight. Join us on this exhilarating journey into the future.
      </p>
    </section>
  );
  
};

export default Section2;