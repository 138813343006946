import React, { useState } from 'react';
import './App.css';
import FAQItem from './FAQItem'; 
import { useDynamicGlitchEffect } from './headlines'; 

const faqData = [
  { 
    question: "What is Prophecy?", 
    answer: "Prophecy is an innovative platform designed for making predictions across a variety of domains, leveraging gamification and blockchain technology to ensure a secure, transparent, and engaging experience." 
  },
  { 
    question: "How does Prophecy utilize blockchain technology?", 
    answer: "Prophecy integrates blockchain technology to ensure the integrity and transparency of every prediction made on the platform. This includes the use of smart contracts for secure transactions and decentralized finance (DeFi) principles to manage rewards and stakes." 
  },
  { 
    question: "Can anyone participate in Prophecy's prediction markets?", 
    answer: "Yes, Prophecy is built on a permissionless framework, allowing anyone with an internet connection to participate. Our platform prioritizes user accessibility and inclusivity, ensuring a diverse community of predictors." 
  },
  { 
    question: "What makes Prophecy different from other prediction platforms?", 
    answer: "Prophecy stands out through its gamified approach to predictions, modular design for interoperability, and its commitment to a permissionless user experience. These features, combined with our integration of blockchain technology, set a new standard for prediction markets." 
  },
  { 
    question: "Are there rewards for participating in predictions on Prophecy?", 
    answer: "Absolutely. Prophecy rewards users for their insights and participation. Rewards are distributed based on the accuracy of predictions and the level of engagement, encouraging a competitive yet collaborative environment." 
  },
  { 
    question: "How can I start making predictions on Prophecy?", 
    answer: "Getting started is easy. Simply create an account on our platform, explore the available prediction markets, and begin making your forecasts. Prophecy offers a user-friendly interface to ensure a smooth experience for all participants." 
  },
  { 
    question: "What are the future plans for Prophecy?", 
    answer: "Our roadmap includes expanding the range of prediction markets, enhancing our blockchain infrastructure for greater scalability and security, and developing native mobile applications to make Prophecy accessible anywhere, anytime. We're constantly innovating to shape the future of predictive markets." 
  }
];

const Section3 = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  // Use the dynamic glitch effect without hover handlers
  const headlineText = useDynamicGlitchEffect("Decoding Prophecy: Your Questions Answered", 700);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <section className="section faq-section">
      {/* Dynamic glitch effect headline */}
      <h1 className="glitch-headline">
        {headlineText}
      </h1>
      {/* Dynamic glitch effect placeholder text */}
      <p className="glitch-placeholder long-placeholder-text">
      </p>
      <div className="faq-container">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            index={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openFAQ === index}
            toggleFAQ={toggleFAQ}
          />
        ))}
      </div>
    </section>
  );
};

export default Section3;