// App.js
import React from 'react';
import Header from './Header';
import DynamicGradients from './DynamicGradients';
import Section1 from './Section1'; // Import the new section
import Section2 from './Section2'; // Import the new section
import Section3 from './Section3'; // Import the new section
import Section4 from './Section4'; // Import the new section
import Footer from './Footer'; // Import the new section

function App() {
  return (
    <div className="App">
      <DynamicGradients />
      <Header />
      <main> {/* Use main to wrap the main content of the page */}
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Footer />

      </main>
    </div>
  );
}

export default App;
